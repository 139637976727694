import c3api from "@/c3api";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default function useCarriers() {
  const carrierLoading = ref(false);

  const carriers = ref([]);
  const ecommerceCarriers = ref([]);
  const carrier = ref([]);
  const toast = useToast();
  const router = useRouter();

  const fetchCarriers = async (id) => {
    try {
      carrierLoading.value = true;
      const response = await c3api.get(`/carriers`);
      return (carriers.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      carrierLoading.value = false;
    }
  };
  
  const fetchCarrier = async (id) => {
    try {
      carrierLoading.value = true;
      const response = await c3api.get(`/carriers/${id}`);
      return (carrier.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      carrierLoading.value = false;
    }
  };

  const updateCarrier = async (id, params) => {
    try {
      carrierLoading.value = true;
      const response = await c3api.patch(`/carriers/${id}`, params);
      toast.success("Carrier updated successfully.");
      await fetchCarrier(id);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      carrierLoading.value = false;
    }
  };

  const fetchEcommerceCarriers = async () => {
    try {
      carrierLoading.value = true;
      const response = await c3api.get(`/ecommerce/carriers`);
      return (ecommerceCarriers.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      carrierLoading.value = false;
    }
  };

  const createCarrier = async (params) => {

    try {
      await c3api.post("/carriers", params);
      toast.success("Carrier Added Successfully");
      router.push("/carriers");
    } catch (error) {
      toast.error(error.response.data?.data.join(". "));
    } finally {
      carrierLoading.value = false;
    }
  };

  return {
    carriers,
    fetchCarriers,
    carrierLoading,
    carrier,
    fetchCarrier,
    updateCarrier,
    fetchEcommerceCarriers,
    ecommerceCarriers,
    createCarrier,
  };
}
